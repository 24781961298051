import beansIdLogo from '@images/beans-id-logo.svg'
import { css } from 'aphrodite'
import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'

import {
  BEANS_BRAND_BEANS_ID,
  BEANS_BRAND_STUDENT_BEANS
} from '../../constants'

import { LogoutLink, SupportLink } from './children'
import {
  BEANS_ID_ALT_TEXT,
  BEANS_ID_LOGO_HEIGHT,
  BEANS_ID_LOGO_WIDTH
} from './constants'
import {
  getLogo,
  getLogoAltText,
  getLogoHeight,
  getLogoWidth,
  shouldRenderLogoutLink
} from './helpers'
import styles from './styles'

const Footer = (props) => {
  const renderLogoutLink = () => {
    if (!shouldRenderLogoutLink(props)) return null

    return (
      <LogoutLink
        onLogout={props.onLogout}
        country={props.country}
      />
    )
  }

  const renderGradBeansLogo = () => {
    return (
      <img
        height={BEANS_ID_LOGO_HEIGHT}
        width={BEANS_ID_LOGO_WIDTH}
        className={css(styles.logo)}
        src={beansIdLogo}
        alt={BEANS_ID_ALT_TEXT}
      />
    )
  }

  const renderLogo = () => {
    if (props?.hideLogo) return null

    if (props.beansBrand === BEANS_BRAND_BEANS_ID) return renderGradBeansLogo()

    const consumerGroup = props?.offer?.consumerGroup?.toLowerCase()

    return (
      <img
        height={getLogoHeight(consumerGroup)}
        width={getLogoWidth(consumerGroup)}
        className={css(styles.logo)}
        src={getLogo(consumerGroup)}
        alt={getLogoAltText(consumerGroup)}
      />
    )
  }

  return (
    <footer className={css(styles.wrapper)}>
      {renderLogo()}
      <div>
        <SupportLink country={props.country} />
        {renderLogoutLink()}
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  hideLogo: false,
  offer: {
    consumerGroup: 'STUDENT'
  },
  beansBrand: BEANS_BRAND_STUDENT_BEANS
}

Footer.propTypes = {
  country: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
  viewer: PropTypes.any.isRequired,
  offer: PropTypes.shape({
    consumerGroup: PropTypes.string
  }).isRequired,
  hideLogo: PropTypes.bool,
  beansBrand: PropTypes.string
}

export default createFragmentContainer(Footer, {
  viewer: graphql`
    fragment footer_viewer on AccountsViewer {
      isLoggedIn
    }
  `,
  offer: graphql`
    fragment footer_offer on Offer {
      consumerGroup
    }
  `
})
