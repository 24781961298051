import { captureException } from '@sentry/browser'

import { clearAuthToken } from '@src/client/helpers'

export const logout = async ({ country, onLogout }) => {
  clearAuthToken()

  const logOutUrl = `${process.env.ACCOUNTS_URL}/${country}/authorisation/logout`

  await fetch(logOutUrl, {
    credentials: 'include'
  })
    .catch((err) => {
      captureException(err)
    })
    .finally(() => {
      onLogout()
    })
}
