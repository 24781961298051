/**
 * @generated SignedSource<<45a4c7073ceee61969b2d1226b78f432>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "footer_offer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "consumerGroup",
      "storageKey": null
    }
  ],
  "type": "Offer",
  "abstractKey": null
};

node.hash = "fa71c0489f36ce48a70976b8f8ed1b4e";

module.exports = node;
