import ExternalLink from '@components/external_link'

const MarkdownAnchor = ({ children, href }) => (
  <ExternalLink
    href={href}
    text={children[0]}
    target={'_blank'}
    colour={'uiBlue'}
  />
)

export default MarkdownAnchor
