import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import { TextLink } from '@thebeansgroup/sb-ui/ctas'

import ImplementationContext from '@src/contexts/implementation'

import { isInappImplementationType } from '@helpers/implementation'
import { sendExternalLinkClickEvent } from '@helpers/inapp'

const ExternalLink = ({ text, href, colour, fontSize, baseTone }) => {
  const implementation = useContext(ImplementationContext)

  const defaultProps = {
    underlined: true,
    colour,
    text,
    fontSize,
    baseTone
  }

  if (isInappImplementationType(implementation)) {
    return (
      <TextLink
        {...defaultProps}
        onClick={() => sendExternalLinkClickEvent(href)}
      />
    )
  }

  return (
    <TextLink
      {...defaultProps}
      href={href}
      target={'_blank'}
    />
  )
}

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired
}

export default ExternalLink
