import { css } from 'aphrodite'
import PropTypes from 'prop-types'

import { withI18n } from '@thebeansgroup/_i18n'
import { Colours } from '@thebeansgroup/sb-ui/style_helpers'
import { Footnote } from '@thebeansgroup/sb-ui/typography'

import { logout } from './helpers'
import styles from './styles'

const LogoutLink = ({ t, country, onLogout }) => {
  const renderSeparator = () => {
    return (
      <span className={css(styles.separator)}>
        <Footnote
          component='span'
          colour={Colours('grey', 500)}
        >
          |
        </Footnote>
      </span>
    )
  }

  return (
    <div className={css(styles.wrapper)}>
      {renderSeparator()}
      <div
        className={css(styles.logout)}
        onClick={async () => await logout({ country, onLogout })}
        role={'button'}
      >
        <Footnote
          component='span'
          colour={Colours('grey', 500)}
        >
          {t('a_log_out')}
        </Footnote>
      </div>
    </div>
  )
}

LogoutLink.propTypes = {
  onLogout: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
}

export default withI18n(LogoutLink)
