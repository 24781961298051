import { isObject, trimObjectStringValues } from '@helpers/index'

export const getRouteParams = (props) => {
  if (!isObject(props?.match?.params)) return {}

  return trimObjectStringValues(props?.match?.params)
}

export const getRouteQueryParamValue = (props, value) => {
  if (!isObject(props?.match?.location?.query)) return false

  return props.match.location.query[value]
}
