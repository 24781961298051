import { StyleSheet } from 'aphrodite'

import { baby } from '@thebeansgroup/sb-ui/style_helpers/queries'

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '28px',

    ...baby({
      marginBottom: '20px'
    })
  },

  logo: {
    marginBottom: '16px',

    ...baby({
      marginBottom: '12px'
    })
  },

  link: {
    marginBottom: '8px'
  }
})

export default styles
