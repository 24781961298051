import ErrorPage from '@pages/error_page'
import * as Sentry from '@sentry/browser'
import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'

import { hasClientSideRelayError } from '@src/relay/helpers'

import Loader from '@components/loader'

import { MESSAGE } from './constants'
import { doAllOffersHaveUids } from './helpers'

const OfferErrorHandler = (props) => {
  const { connectOfferPage, relayError, children, loaderText } = props
  if (hasClientSideRelayError(relayError)) return <ErrorPage />

  if (props?.loading) return <Loader text={loaderText} />

  if (
    !connectOfferPage?.offer?.uid ||
    !doAllOffersHaveUids(connectOfferPage.offers)
  ) {
    Sentry.captureException(new Error(`Error: ${MESSAGE}`))
    return (
      <ErrorPage
        status={404}
        message={MESSAGE}
      />
    )
  }

  return children
}

OfferErrorHandler.propTypes = {
  loading: PropTypes.bool.isRequired,
  connectOfferPage: PropTypes.object,
  relayError: PropTypes.object,
  children: PropTypes.any,
  loaderText: PropTypes.string
}

export default createFragmentContainer(OfferErrorHandler, {
  connectOfferPage: graphql`
    fragment offerErrorHandler_connectOfferPage on ConnectOfferPage {
      offer {
        uid
      }
      offers {
        uid
      }
    }
  `
})
