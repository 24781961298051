import cookies from 'js-cookie'

import { AUTH_TOKEN_NAME } from './constants'

export const clearAuthToken = () => {
  cookies.remove(AUTH_TOKEN_NAME, {
    sameSite: 'None',
    secure: true
  })
}
