import gradsLogo from '@images/student-beans-grads-logo.svg'
import studentLogo from '@images/student-beans-logo-new.svg'

export const LOGOS = {
  student: {
    logo: studentLogo,
    altText: 'StudentBeans',
    logoHeight: '24px',
    logoWidth: '170px'
  },
  graduate: {
    logo: gradsLogo,
    altText: 'StudentBeans Grads',
    logoHeight: '38px',
    logoWidth: '170px'
  }
}
export const BEANS_ID_ALT_TEXT = 'Beans iD'
export const BEANS_ID_LOGO_HEIGHT = '24px'
export const BEANS_ID_LOGO_WIDTH = '170px'
