import beansIdLogo from '@images/beans-id-logo.svg'

import {
  BEANS_ID_ALT_TEXT,
  BEANS_ID_LOGO_HEIGHT,
  BEANS_ID_LOGO_WIDTH,
  LOGOS
} from '../constants'

export const shouldRenderLogoutLink = (props) => {
  return props?.viewer?.isLoggedIn
}

export const getLogo = (consumerGroup) => {
  const logo = LOGOS[consumerGroup]?.logo || beansIdLogo

  return logo
}

export const getLogoAltText = (consumerGroup) => {
  const altText = LOGOS[consumerGroup]?.altText || BEANS_ID_ALT_TEXT

  return altText
}

export const getLogoHeight = (consumerGroup) => {
  const logoHeight = LOGOS[consumerGroup]?.logoHeight || BEANS_ID_LOGO_HEIGHT

  return logoHeight
}

export const getLogoWidth = (consumerGroup) => {
  const logoWidth = LOGOS[consumerGroup]?.logoWidth || BEANS_ID_LOGO_WIDTH

  return logoWidth
}
