import PropTypes from 'prop-types'

import { withI18n } from '@thebeansgroup/_i18n'

import ExternalLink from '@components/external_link'

import { getSupportUrl } from './helpers'

const SupportLink = (props) => (
  <ExternalLink
    href={getSupportUrl(props)}
    colour={'grey'}
    text={props.t('a_support')}
    fontSize={-2}
    baseTone={'400'}
  />
)

SupportLink.propTypes = {
  country: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
}

export default withI18n(SupportLink)
