import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
  wrapper: {
    display: 'inline-block'
  },

  separator: {
    display: 'inline-block',
    margin: '0px 5px'
  },

  logout: {
    display: 'inline-block',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
})

export default styles
