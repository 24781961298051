import MarkdownToJsx from 'markdown-to-jsx'
import PropTypes from 'prop-types'

import MarkdownAnchor from './children/markdown_anchor'

const options = {
  overrides: {
    a: MarkdownAnchor
  }
}

const Markdown = ({ children }) => {
  if (!children) return null

  return <MarkdownToJsx options={options}>{children}</MarkdownToJsx>
}

Markdown.defaultProps = {
  children: ''
}

Markdown.propTypes = {
  children: PropTypes.string
}

export default Markdown
